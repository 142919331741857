import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { format } from 'date-fns';

import config from '../../../helpers/styles/theme';

const StyledArticleCard = styled.div`
  background-image: url('${props => props.image}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 550px;
  width: auto;
  margin-bottom: 16px;

  .Article-Card__Content {
    background-color: rgba(${props =>
      config.scheme.articleRgba[props.categoryColour] || '255, 255, 255, .8'});
    height: 550px;
    width: 100%;
    padding: 32px;
    color: ${props => (props.categoryColour ? '#fff' : '#6d6e71')};
    position: relative;

    @media screen and (min-width: 768px) {
      width: calc(50% - 8px);
    }

    @media screen and (min-width: 960px) {
      width: calc(50% - 40px);
    }
  }

  .Article-Card__Inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 64px);
  }

  .Article-Card__Content--Central {
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: 32px;
      padding-left: 48px;
      width: calc(50% - 8px);
    }
  }

  .Article-Card__Title {
    font-size: 26px;
  }

  .Article-Card__Date {
    display: block;
    margin: 40px 0 16px;
    font-size: 16px;
  }

  .Article-Card__Link {
    color: #F58021;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
  }
`;

const getArticleRgba = category => {
  const categories = {
    'business-and-commercial': 'business-commercial',
    'farm-and-estates': 'farms-estates',
    'you-and-your-family': 'you-and-your-family',
    'your-home': 'your-home',
  };

  if (category) {
    return categories[category];
  }
  return 'generic';
};

const ArticleCard = ({
  id,
  title,
  date,
  category,
  image,
  isCentral,
  slug,
  isClientSide,
}) => (
  <StyledArticleCard categoryColour={getArticleRgba(category)} image={image}>
    <div className="Article-Card" data-post-id={id}>
      <div
        className={classnames('Article-Card__Content', {
          'Article-Card__Content--Central': isCentral,
        })}
      >
        <div className="Article-Card__Inner">
          <p
            className="Article-Card__Title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <small className="Article-Card__Date">
            {isClientSide ? format(date, 'Do MMMM YYYY') : date}
          </small>
          <Link className="Article-Card__Link" to={`/news/${slug}`}>
            Read More
          </Link>
        </div>
      </div>
    </div>
  </StyledArticleCard>
);

ArticleCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isCentral: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  isClientSide: PropTypes.bool,
};

ArticleCard.defaultProps = {
  isCentral: false,
  isClientSide: false,
};

export default ArticleCard;
